<template>
  <div class="content">
    <div class="cell">
      <template v-if="list.length > 0">
        <div class="item" v-for="(item, key) in list" :key="key">
          <div class="name">{{ item.name }}</div>
          <div class="btn">
            <el-button size="mini" type="success" @click="preview(item)">
              预览
            </el-button>
            <el-button
              v-if="item.name"
              size="mini"
              type="primary"
              @click="download(item)"
            >
              下载
            </el-button>
            <!-- <el-upload
              class="upload-demo"
              :action="qiniu.actionPath"
              :data="qiniu.postData"
              accept=".jpg,.png,.pdf,.xls,.xlsx,.docx,.pptx"
              :on-success="uploadSuccess"
              :on-error="uploadError"
              :before-upload="beforeUpload"
              :show-file-list="false"
            >
              <el-button size="small" type="primary">重新上传</el-button>
            </el-upload> -->
            <el-button size="small" type="primary" @click="again"
              >重新上传</el-button
            >
            <!-- <el-button size="mini" type="danger" @click="del(key)">
            删除
          </el-button> -->
          </div>
        </div>
      </template>
      <el-upload
        v-if="list.length == 0"
        class="upload-demo"
        :action="qiniu.actionPath"
        :data="qiniu.postData"
        accept=".jpg,.png,.pdf,.xls,.xlsx,.docx,.pptx"
        :on-success="uploadSuccess"
        :on-error="uploadError"
        :before-upload="beforeUpload"
        :show-file-list="false"
        drag
      >
        <!-- <el-button size="small" type="primary">上传</el-button> -->
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__text">只能上传.jpg,.png,.pdf,.xls,.xlsx,.docx,.pptx格式</div>
      </el-upload>
    </div>
  </div>
</template>
<script>
import { getQiniuToken } from "@/api/common";
import { guid } from "@/utils/index";
export default {
  name: "Batch_add",
  props: {
    title: {
      type: String,
      default: "",
    },
    type: {
      type: Number,
      default: 1,
    },
    isBtn: {
      type: Boolean,
      default: true,
    },
    list: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      loading: false,
      index: "",
      qiniu: {
        actionPath: "",
        baseUrl: "",
        postData: {},
      },
    };
  },
  created() {
    getQiniuToken().then((response) => {
      console.log(response);
      // token可多次使用
      this.qiniu.actionPath = response.data.uploadUrl;
      this.qiniu.baseUrl = response.data.baseUrl;
      // console.log(response)
      if (!this.qiniu.postData.token) {
        this.qiniu.postData.token = response.data.token;
      }
    });
  },
  mounted() {},
  methods: {
    getUrlBase64(url) {
      return new Promise((resolve) => {
        let canvas = document.createElement("canvas");
        let ctx = canvas.getContext("2d");
        let img = new Image();
        img.crossOrigin = "Anonymous"; //允许跨域
        img.src = url;
        img.onload = function () {
          canvas.height = 300;
          canvas.width = 300;
          ctx.drawImage(img, 0, 0, 300, 300);
          let dataURL = canvas.toDataURL("image/png");
          canvas = null;
          resolve(dataURL);
        };
      });
    },
    judgeType(name) {
      let result = "";
      let suffix = "";
      try {
        var flieArr = name.split(".");
        suffix = flieArr[flieArr.length - 1];
      } catch (err) {
        suffix = "";
      }
      let imglist = ["png", "jpg", "jpeg", "bmp", "gif","pdf"];
      result = imglist.some(function (item) {
        return item == suffix;
      });
      return result;
    },
    preview(item) {
      let res = this.judgeType(item.url);
      if (res) {
        let routeUrl = item.url.replace(/http:/, "https:");
        window.open(routeUrl, "_blank");
      } else {
        let routeUrl = item.url;
        let officeUrl =
          "https://view.officeapps.live.com/op/view.aspx?src=" + routeUrl;
        window.open(officeUrl, "_blank");
      }
    },
    download(item) {
      console.log(item, "===========");
      let res = this.judgeType(item.url);
      if (res) {
        this.getUrlBase64(item.url).then((base64) => {
          let link = document.createElement("a");
          link.href = base64;
          link.download = item.name;
          link.click();
        });
      } else {
        let routeUrl = item.url.replace(/http:/, "https:");
        window.open(routeUrl, "_parent");
      }
    },
    del(key) {
      this.list.splice(key, 1);
    },
    beforeUpload(file) {
      this.qiniu.postData.key =
        guid() + "." + file.name.split(".")[file.name.split(".").length - 1];
    },
    uploadSuccess(res, file) {
      let url = {
        url: "",
        name: "",
      };
      url.url = this.qiniu.baseUrl + "/" + res.key;
      url.name = file.name;
      // this.list.push(url)
      this.list[0] = url;
      this.$forceUpdate(); //强制更新
      console.log(this.list);
      // this.list[0].name = file.name;
      // this.list[0].file = url;
      // this.loading = false;
    },
    again() {
      this.$emit("again");
    },
    uploadError() {},
  },
};
</script>
<style lang="scss" scoped>
.title {
  margin-bottom: 20px;
}
.upload-demo {
  margin: 0 10px;
}
.content {
  width: 50%;
  // margin: 0 auto;

  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  }
  .cell {
    // padding: 10px 20px;
    // border: 1px solid #c4c4c4;
    // max-height: 250px;
    // overflow: hidden;
    // overflow: auto;
    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      // margin-top: 10px;
      // margin-bottom: 20px;
      margin-left: 10px;
      .name {
        margin-right: 15px;
      }
      .select {
        width: 20px;
        height: 20px;
      }
      .btn {
        display: flex;
      }
    }
  }
  .bottom_row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 100px;
  }
  .bottom {
    display: flex;
  }
}
</style>