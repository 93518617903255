<template>
  <div class="app-container">
    <!-- <div class="filter-container">
      <el-date-picker
        v-model="listQuery.school_year"
        type="year"
        class="filter-item"
        value-format="yyyy"
        placeholder="选择学年"
      >
      </el-date-picker>
      <el-button class="filter-item" type="primary" @click="handleFilter">
        搜索
      </el-button>
    </div> -->
    <div class="box">
      <div class="box_item">
        <span type="primary" style="margin: 10px 0px">年份:</span>
        <el-date-picker
          v-model="newYear"
          type="year"
          class="filter-item"
          value-format="yyyy"
          style="margin-left: 10px"
          @change="changeYear"
          placeholder="选择学年"
        >
        </el-date-picker>
      </div>
      <div class="box_item">
        <span type="primary" style="margin: 10px 0px"> 学校作息时间表: </span>
        <Upload :list="form.school_schedule" @again="again(1)" />
      </div>
      <div class="box_item">
        <span type="primary" style="margin: 10px 0px">校历:</span>
        <Upload :list="form.school_calendar" @again="again(2)" />
      </div>
    </div>
    <div class="btn_box">
      <el-button
        class="filter-item"
        type="primary"
        @click="submit"
        :loading="btnLoading"
      >
        提交
      </el-button>
    </div>
  </div>
</template>

<script>
import { getQiniuToken } from "@/api/common";
import request from "@/utils/request";
import Upload from "@/components/Upload/index.vue";
export default {
  components: {
    Upload,
  },
  data() {
    return {
      listQuery: {
        school_year: null,
      },
      form: {
        id: "",
        school_year: "",
        school_schedule: [],
        school_calendar: [],
      },
      btnLoading: false,
      newYear: "",
    };
  },
  created() {
    var newData = new Date().getMonth() + 1;
    var year = new Date().getFullYear();
    if (newData >= 3 && newData <= 7) {
      this.newYear = year - 1 + "";
    } else {
      this.newYear = year + "";
    }
    this.getList();
  },
  methods: {
    again(type) {
      if (type == 1) {
        this.form.school_schedule = [];
      } else {
        this.form.school_calendar = [];
      }
    },
    changeYear() {
      this.getList();
    },
    handleFilter() {
      this.newYear = this.listQuery.school_year;
      this.getList();
    },
    getList() {
      this.listLoading = true;
      this.form = {
        id: "",
        school_year: "",
        school_schedule: [],
        school_calendar: [],
      };
      this.$forceUpdate(); //强制更新
      request({
        url: "/api/schoolend/school/schoolInfo",
        method: "get",
        params: {
          school_year: this.listQuery.school_year || this.newYear,
        },
      }).then((response) => {
        if (response.data) {
          this.form = response.data;
          this.form.school_schedule = [this.form.school_schedule];
          this.form.school_calendar = [this.form.school_calendar];
        } else {
          this.form = {
            id: "",
            school_year: "",
            school_schedule: [],
            school_calendar: [],
          };
        }

        // console.log(this.form);
        this.listLoading = false;
      });
    },
    submit() {
      this.form.school_year = this.newYear;
      this.form.school_schedule = this.form.school_schedule[0];
      this.form.school_calendar = this.form.school_calendar[0];
      if (!this.form.school_year) {
        this.$message.error("请选择学年!!");
        return;
      }
      this.btnLoading = false;
      request({
        url: "/api/schoolend/school/schoolStore",
        method: "post",
        data: this.form,
      })
        .then((response) => {
          this.btnLoading = false;
          this.getList();
          this.$message({
            type: "success",
            message: "操作成功",
          });
        })
        .catch((err) => {
          this.btnLoading = false;
        });
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.box {
  width: 60%;
  margin: 20px auto;
  border: 1px solid #c4c4c4c4;
  padding: 20px;
  // margin-bottom: 2;
  .box_item {
    // width: 80%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    // justify-content: space-between;
    span {
      width: 120px;
    }
  }
}
.filter-item {
  // margin-left: 20%;
  margin: 0 auto;
}
.btn_box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
  margin: 0 auto;
}
</style>
